.ant-spin{
    color: #fff !important;
}
.form-log-in-container .ant-input{
    padding: 1em 11px !important
}
.form-log-in-container .ant-input-affix-wrapper{
    padding: 0 11px !important
}
.form-log-in-container .ant-form-item-explain-error{
    padding-left: 0.7em;  
}