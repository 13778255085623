.flex-end-contenttv .ant-card {
    width: 53% !important;
    height: 30% !important;
    background: #8dbbdb !important;
    border-radius: 8px !important;
    margin-left: 0.7em;
  }
  .add-new-tv-title{
    font-family: Helvetica;
    font-size: 1.5em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .Rectangle-add-tv{
    width: 18%;
    height: 38%;
    border-radius: 2px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    background-color: #fff;
    text-align: center;
    top: -24px;
    left: 30px;
    position: absolute;
  }
  .plus-icon{
    width: 36%;
    padding-top: 1.2em;
   
  }
  .flex-end-contenttv{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;  
  }
  .Rectangle-41-butntv-edit{
    border-radius: 7px;
    border: solid 1px #d2d2d2;
    background-color: #8dbbdb; 
  }
  .tv-Container .ant-btn {
   
    height: 43%;
    padding: 11px 45px;
    font-size: 14px;
    color: white !important;
    border-color: #d9d9d9;
    /* background: #fff; */
    border-radius: 7px !important;
    /* border: solid 1px #8dbbdb !important; */
    /* background-color: #8dbbdb ; */
    font-family: Helvetica;
    font-size: 1em;
    font-weight: bold;

}
.grid-item-cardtv{
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4em;
    justify-items: start;
    margin-top: 5em;

}
.tv-Container .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100% !important;
    min-width: 82% !important;
    margin-top: 2em !important;
    margin-bottom: 1em !important;
    margin-left: 0em !important;
}
.tv-Container .ant-card-body {
    padding: 24px;
    padding-bottom: 0;
}
.img-btn-rec{
    width: 11%;
    padding-bottom: 1em; 
}
.Edit-link-tv{

    line-height: 1.17;
    color: #0b77c2;
}
.In-Active-title{
    margin-top: -5%;
    border-radius: 5px;
    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 16%);
    color: #fff;
    text-align: left;
    font-weight: bold;
    padding: 1em 2em;
    font-family: Helvetica;
    font-size: 1.4em;
    width: 97% !important;
    margin-left: 0.5em !important;

}
.In-Active-title-select{
  margin-top: -5%;
  border-radius: 5px;
  box-shadow: 0 6px 10px 0 rgb(0 0 0 / 16%);
  color: #fff;
  text-align: left;
  font-weight: bold;
  padding: 1em 2em;
  font-family: Helvetica;
  font-size: 1.4em;
  width: 96% !important;
  margin-left: 0.45em !important;
}
.Add-Music{
    /* padding: 2em 0.5em; */
    font-family: Helvetica;
    font-size: 2em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
    color: #4e4e4e;   
}
.Animation-button:last-child {
  width: 66%;
}
.ant-radio-checked .ant-radio-inner {
  border-color: gray !important;
}
.ant-radio-inner::after {
  background-color: gray !important
}
.uploadMedia-music .ant-btn {
  border: none !important;
  text-align: left;
}
.btn-upload-file{
  background-color: rgba(11, 119, 194, 0.12) !important;
  width: 84vh;
  height: 6vh;
}
.title-addMedia{
  font-family: Helvetica;
  font-size: 2em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  padding-right: 0.5em;
}
.title-sub-addmedia{
  font-family: Helvetica;
  font-size: 1.2em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
  padding-right: 1em;
}
.add-container-media-div .ant-checkbox-inner{
  width: 24px !important;
  height: 24px !important;
}
.addmedia-container-btn .ant-btn {
  background-color: #0b77c2 !important;
  color: white !important;
  font-family: Helvetica !important;
  font-weight: bold !important;
  border-radius: 6px !important;
  /* margin-right: 2em !important */
}
.upload-div-music{
  display: flex;
    flex-direction: row;
    align-items: center;
}
.upload-div-music .ant-btn {
    color: #4e4e4e !important;
    font-family: Helvetica !important;
    font-weight: 500 !important;
    border: none !important;
    text-align: left !important;
}
.btn-upload-music{
    background-color: #f6f6f6 !important;
    width: 41vh !important; 
}
/* .ant-list-item{
  color: #e2e2e2 !important;
} */
.classSubmit-list .ant-btn{
  color: #cacaca !important;
}
.ant-switch-checked {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.tv-Container .ant-checkbox{
  top: -0.3em !important;
}
.card-list-selectedtv .ant-list-item {
  color: #0B77C2!important;
  font-size: 1.2em;
}
.card-list-selectedtv .ant-list-item-meta-title {
  font-size: 1em !important;
}
.ant-progress-line{
  width: 79% !important;
}
.btn-upload-music .ant-progress-line{
  width: 100% !important;
}
.react-jinke-music-player .music-player-controller {
  color: #1890ff !important;
}
.react-jinke-music-player-main .music-player-panel .panel-content .rc-slider-handle, .react-jinke-music-player-main .music-player-panel .panel-content .rc-slider-track {
  background-color: #1890ff !important;
}
.react-jinke-music-player-main svg:active, .react-jinke-music-player-main svg:hover {
  color: #1890ff !important;
}
.react-jinke-music-player .music-player-controller .music-player-controller-setting {
  background: rgb(25 144 255 / 47%) !important;
}
.audio-lists-panel-content .audio-item.playing, .audio-lists-panel-content .audio-item.playing svg {
  color: #1890ff !important;
}
.react-jinke-music-player-main .loading svg {
  color: #1890ff !important;
}
.ant-picker{
  margin-left: 1em !important;
  width: 7em !important;
}
.css-i4bv87-MuiSvgIcon-root {
  width: 1.5em !important;
  height: 1.5em !important;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0em !important;
}
.container-card-list-doc .ant-list-item{
  justify-content: flex-start !important;
}
/* .keep-scrolling {
  height: 200;
  overflow: auto
} */
/* Hide scrollbar for Chrome, Safari and Opera */
/* .keep-scrolling::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* .keep-scrolling {
-ms-overflow-style: none;  /* IE and Edge */
/* scrollbar-width: none;  /* Firefox */
/* } */
.ant-list-item-meta-title{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80%;
}
.TV-Huber-name{
  font-family: Helvetica;
  font-size: 1.8em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  /* padding-left: 0.5em; */
  margin-bottom: 1em;
}
.add--switchers-list .ant-radio-wrapper {
  font-size: 14px;
  margin-right: 2.7em;
}
.flex-not-found{
  display: flex !important;
  justify-content: center !important;
}
.Not-found-text{
  font-family: Helvetica;
  font-size: 1.5em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #00000096;
  padding-left: 0.5em;
}
.ant-form-item-control-input-content{
  max-width: 100vh !important
}
.ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name {
  color: rgba(0, 0, 0, 0.85) !important;
}
.upload-div-music .ant-upload-list-item-card-actions{
  display: none !important;
}
.edit-Huber-container{
    display: flex;
    align-items: center;
    align-content: center;
    padding-right: 1em;
    margin-bottom: 0.6em;
    justify-content: space-between;
}
.edit-Huber-container .ant-input-group.ant-input-group-compact > *:last-child, .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.edit-Huber-container .ant-btn-primary {
  color: #fff;
  border-color: #0b77c2;
  background: #0b77c2;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.edit-Huber-container .ant-input-group.ant-input-group-compact > *:first-child, .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
/* .edit-Huber-container .ant-input{
  font-size: 1.8em;
}
.edit-Huber-container .ant-btn {
  height: 6.5vh;
} */
.card-index-tvs-container .ant-card-bordered .ant-card-cover {
  align-items: center;
  display: flex;
  justify-content: center;
}
.card-index-tvs-container .ant-image-mask{
  margin-top: -8%;
  max-width: 93%;
  height: 29vh;
  border-radius: 10px;
  left: 3.5%;
}
.card-index-tvs-container .ant-image {
  position: relative;
  display: inline-block;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.ant-image-mask {
  border-radius: 16px;
}
