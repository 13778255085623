body {
  padding: 0 0 30px 0;
  background-color: #fafafa;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
  font-family: open sans, Helvetica, Arial, sans-serif;
}

.img-login-background{
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
}
.Huber-title-login{
  position: absolute;
    top: 0%;
    left: 0%;
    color: #0B77C2;
    font-family: Helvetica;
    font-size: 3rem;
    font-weight: 900;
    padding: 1em;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
}
.card-singIn{
  width: 50%;
  position: absolute;
  right: 0%;
  top: 0%;
  
}
.Start-here{
  font-family: Helvetica;
  font-size: 2.6em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: normal;
  text-align: left;
  color: #333;
}
.input-login{
  margin-bottom: 1em;
}
.span-title-input{
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #848484;
  padding-left: 0.8em;

}
.Field {
  height: 60% !important;
  border-radius: 10px !important;
  background: white;
  margin-top: 0.4em !important;
  margin-bottom: 1em !important;
}
.Row-log-in{
  padding: 12em;

}

.log-In-container .ant-btn{
  color: #fff !important;
  border-color: #1879bf !important;
  background: #1879bf !important;
  width: 100%;
  border-radius: 10px !important;
  margin-top: 2em;
  text-align: center;
  font-size: 1.2em;
  padding: 1em;
  font-weight: bold;
  height: auto !important;
  padding: 0.5em;
}
.button-sign{
  display: flex;
  justify-content: center;
}
#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #fff !important;
  height: 100vh;
}
.image-background-sidebar{
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;

  object-fit: cover;
}
.Huber-logo-sidebar{
  color: #0B77C2;
  font-family: Helvetica;
  font-size: 1.2rem;
  font-weight: 900;
  padding-left: 0.5em;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
}
.brand{
display: flex;
align-items: center;
align-content: center;
justify-content: center;
padding: 2em 1em;
}
.ant-layout-sider{
  min-width: 300px !important;
  min-height: 100vh !important;
}
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background: transparent !important;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
    color: rgba(255, 255, 255, 0.65);
    background-color: #464646;
}
.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 50% !important;
    line-height: 4 !important;
    border-radius: 10px !important;
}
.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 1.5em !important;
}
.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
  margin-top: 2.5em;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-vertical .ant-menu-item, .ant-menu-dark.ant-menu-vertical-left .ant-menu-item, .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  margin-left: 1.5em !important;
  margin-right: 1.5em !important;
}
.TVs-span{
  font-size: 1.2em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 82% !important;
  min-width: 82% !important;
  margin-top: 0 !important;
  margin-bottom: 2em !important;
  margin-left: 2em !important;
}
.trigger{
  display: flex;
  justify-content: flex-end;
}
.flex-top-bar{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  margin-top: -5%;
  margin-bottom: 4%;
}
.css-1w4vsez-MuiFormControl-root{
  width: 26% !important; 
}
.mb-24{
  margin: 6em  0 0;

}
.ant-layout{
  height: 100% !important;
}
.ant-layout-sider {
  background: #fff !important;
}
.css-dms7x9 {
  align-items: flex-end !important;
}