/* .grid-item-watchtv{
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0em;
} */
.grid-item-watchtv .ant-card-head{
    padding: 0 !important;
    border-bottom: none !important;
    /* margin-bottom: -2em !important; */
}
.grid-item-watchtv .ant-card-body{
    padding: 0 !important;

}
.grid-item-watchtv .ant-card-head-title{
    padding: 9px 15px !important;
}